@import '../../index.scss';
.payment_success_screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .payment_success_wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .payment_success_text1 {
      font-size: 24px;
      font-family: 'Poppins-Regular';
      padding: 10px 0px;
    }
    .payment_success_text2 {
      font-size: 30px;
      font-family: 'Poppins-Medium';
      padding-bottom: 10px;
    }
    .button_container {
      width: 100%;
      padding: 10px 0px;
    }
    .receipt_download_text {
      font-size: 16px;
      font-family: 'Poppins-Medium';
      color: #9da3a1;
      cursor: pointer;
    }
  }
}
