@import "../../index.scss";

.home_card_container{
  background-color: $cardColor;
  width: 210px;
  padding: 20px;
  border-radius:20px;
  cursor: pointer;
  .home_card_icon_wrap{
    background-color: $secondaryDarkText;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    .home_card_icon{
      width: 25px;
      height: 25px;
    }
  }
  .home_card_title{
    font-size: 16px;
    font-family: Poppins-Regular;
  }
  .home_card_count{
    font-size: 24px;
    font-weight: bold;
  }
}