@import "../../../index.scss";

.nav_btn_container {
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -15px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  display: flex;
  justify-content: center;
  .nav_btn_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.nav_btn_container:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
