@import '../../../index.scss';
.signup_cotainer {
  height: 100vh;
  width: 100vw;
  background-color: $buttonTextColor;
  display: flex;
  align-items: center;
  justify-content: center;
  .signup_wrapper {
    width: 30%;
    .signup_form {
      // background-color: red;
      .signup_header {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 24px;
        padding: 30px;
        font-family: 'Poppins-Light';
      }
      .form_control {
        padding: 10px 0px;
      }
      .button_control {
        width: 100%;
      }
      .signup_button,
      .signup {
        margin: 20px 0 !important;
      }
    }
    .plans_container {
      .plans_heading {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        color: $primaryLightText;
        padding: 10px 8px 6px 8px;
      }
      .plans_wrapper {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }
    }
    .signup_header_logo {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .checkbox_container {
    display: flex;
    align-items: center;
    padding-top: 20px;
    gap: 8px;
    .checkbox_wrapper {
      height: 20px;
      width: 20px;
      border-radius: 20px;
    }
  }
}
.login_text {
  display: flex;
  width:100%;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  &:hover{
    cursor: pointer;
  }
}