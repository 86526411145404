@import '../../index.scss';
.signup_cotainer {
  height: 100vh;
  width: 100vw;
  background-color: $buttonTextColor;
  display: flex;
  align-items: center;
  justify-content: center;
  .signup_wrapper {
    width: 30%;
    .signup_form {
      // background-color: red;
      .signup_header {
        width: 100%;
        display: flex;
        justify-content: center;
        // background-color: red;
        font-size: 24px;
        padding: 20px;
      }
      .form_control {
        padding: 10px 0px;
      }
      .button_control {
        width: 100%;
      }
      .signup_button,
      .signup {
        margin: 20px 0 !important;
      }
    }
  }
}
