@import '../../../index.scss';
.payment_form_screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background-color: white;
  .payment_form_container {
    width: 40%;
    height: 90vh;
    // background-color: gray;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .payment_form_wrapper {
      height: 80%;
      width: 80%;
      // background-color: red;
      .payment_header {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #acacac;
        .payment_header_text {
          font-size: 24px;
          font-family: 'Poppins-Medium';
        }
      }
      .payment_paywith_container {
        padding: 30px 0px;
        .pay_with_text {
          font-size: 18px;
          font-family: 'Poppins-SemiBold';
          padding-bottom: 10px;
        }
        .payment_paywith_wrapper {
          display: flex;
          gap: 20px;
          .payment_type_option {
            display: flex;
            gap: 3px;
          }
        }
      }

      .card_number_container {
        .card_number_title {
          font-size: 14px;
          font-family: 'Poppins-Regular';
          padding-top: 16px;
          padding-bottom: 16px;
          color: $primaryDarkColor;
        }
        .card_number_wrapper {
          background-color: $inputBackground;
          padding: 15px 15px 15px 10px;
          border-radius: 12px;
        }
      }

      .expiry_cvv_container {
        display: flex;
        justify-content: space-between;
        padding: 20px 0px;
        .expiry_container {
          width: 45%;
          .expiry_title {
            font-size: 14px;
            font-family: 'Poppins-Regular';
            padding-bottom: 8px;
            color: $primaryDarkColor;
          }
          .expiry_wrapper {
            background-color: $inputBackground;
            padding: 15px 15px 15px 10px;
            border-radius: 12px;
          }
        }
        .cvv_container {
          width: 45%;
          .cvv_title {
            font-size: 14px;
            font-family: 'Poppins-Regular';
            padding-bottom: 8px;
            color: $primaryDarkColor;
          }
          .cvv_wrapper {
            background-color: $inputBackground;
            padding: 15px 15px 15px 10px;
            border-radius: 12px;
          }
        }
      }
      .save_card_details_container {
        display: flex;
        gap: 6px;
        padding: 20px 0px;
        .save_card_details_text {
          font-size: 16px;
          font-family: 'Poppins-Regular';
        }
      }
    }
  }
  .order_summary_container {
    height: 90vh;
    width: 50%;
    background-color: #f9fafa;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    .order_summary_wrapper {
      height: 80%;
      width: 80%;
      .order_summary_header {
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid #acacac;
        .order_summary_header_text {
          font-size: 24px;
          font-family: 'Poppins-Medium';
        }
      }
      .plan_type_container {
        padding: 30px 0px;
        border-bottom: 1px solid #acacac;
        .plan_type_wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .plan_type,
          .plan_value {
            font-size: 18px;
            font-family: 'Poppins-Medium';
          }
        }
        .plan_type_bottom_text {
          font-size: 14px;
          font-family: 'Poppins-Regular';
          color: #acacac;
        }
      }
      .subtotal_container {
        padding: 20px 0px;
        border-bottom: 1px solid #acacac;

        .subtotal_wrapper,
        .tax_wrapper {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding-bottom: 10px;
          .subtotal_text,
          .tax_text {
            font-size: 16px;
            font-family: 'Poppins-Regular';
          }
          .tax_value,
          .subtotal_value {
            font-size: 16px;
            font-family: 'Poppins-Medium';
          }
        }
      }

      .total_container {
        padding: 20px 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .total_text {
          font-size: 16px;
          font-family: 'Poppins-Regular';
        }
        .total_bottom_text {
          font-size: 14px;
          font-family: 'Poppins-Regular';
          color: #acacac;
        }
        .total_value {
          font-size: 32px;
          font-family: 'Poppins-Medium';
        }
      }
    }
  }
  .plans_wrapper{
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
    padding-top: 10px;
  }
  .plans_heading{
    font-size: 24px;
    font-family: 'Poppins-Medium';
  }
}
