@import '../../index.scss';
.payment_screen {
  .payment_screen_header {
    padding: 20px;
    font-size: 24px;
    font-family: 'Poppins-Regular';
  }
  .payment_container {
    background-color: white;
    padding: 30px;
    border-radius: 32px;
    .payment_plancard_container {
      display: flex;
      gap: 20px;
      width: 100%;
    }
    .payment_method_container {
      padding: 30px 0px;
      .payment_method_title {
        font-size: 24px;
        font-family: 'Poppins-Regular';
        padding-bottom: 10px;
      }
      .add_payment_card {
        background-color: #e7eaeb;
        width: fit-content;
        height: fit-content;
        padding: 40px;
        border-radius: 19px;
        cursor: pointer;
      }
    }
    .billing_history_container {
      .billing_history_title {
        font-size: 24px;
        font-family: 'Poppins-Regular';
        padding-top: 20px;
      }
      .billing_history_table_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        font-family: 'Poppins-Medium';
        border-bottom: 1px solid #969fa2;
        padding: 10px 0px;
        color: $primaryDarkText;
      }
      .billing_history_table_body {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        font-family: 'Poppins-Regular';
        padding: 15px 0px;
        border-bottom: 1px solid #e7eaeb;
        color: $primaryDarkText;
      }
      .billing_download,
      .billing_amount,
      .billing_date,
      .billing_plan {
        width: 20%;
      }
      .underline {
        text-decoration: none;
        cursor: pointer;
        width: fit-content;
        color: $primaryDarkText;
      }
    }
  }
  .payment_loading{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .billing_history_container{
    max-height: 500px;
    overflow-y: scroll;
  }
}
