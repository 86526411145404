@import "../../../index.scss";

.input_component {
  width: 100%;
  .input_container {
    width: 100%;
    .input_label_container {
      padding: 0.5em 0 0.3em 0.5em;
      .input_label {
        color: $primaryLightText;
      }
      .input_label_focus {
        color: $primaryDarkColor;
      }
    }
    .input_wrapper {
      .input {
        display: flex;
        width: 100%;
        height: 55px;
        position: relative;
        .field__input {
          width: 100%;
          outline: none;
          padding: 0 1em;
          background-color: $inputBackground;
          border-radius: 16px;
          border: none;
          color: $primaryDarkText;
        }

        .field__input:focus {
          background-color: $buttonTextColor;
          border: 2px solid $outline;
        }

        .icon_container_end {
          position: absolute;
          right: 0;
          top: 0;
          width: 55px;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 0.3em;
          cursor: pointer;
          .input_icon {
            width: 30px;
            height: 30px;
          }
          // background-color: aqua;
        }

        .icon_container_start {
          position: absolute;
          left: 0;
          top: 0;
          width: 55px;
          height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 0.3em;
          cursor: pointer;
          .input_icon {
            width: 30px;
            height: 30px;
          }
          // background-color: aqua;
        }
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}