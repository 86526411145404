  $primaryDarkColor: #10213F;
  $primaryDarkText: #0B162B;
  $primaryLightText: #8E8E93;
  $primaryLightColor: #586479;
  $secondaryDarkColor: #E73F3F;
  $secondaryLightColor: #FFF4EF;
  $secondaryDarkText: #F16722;
  $secondaryLightText: #8E8E93;
  $background: #F8F9FB;
  $secondaryBackground: #F0F5FF;
  $inputBackground: $background;
  $buttonTextColor: #FFFFFF;
  $outline: #F1F1F4;
  $focusOutLine: #C9C9C9;
  $otpOutLine: #EBEBEB;
  $cancel: #E73F3F;
  $answerColor: #31A61F;
  $cardColor: #FFFFFF;

  ::placeholder { 
    opacity: 0.4; 
  }

@font-face {
  font-family: "Poppins-Black";
  color: $primaryDarkColor !important;
  font-weight: 900;
  src: url(assets/fonts/Poppins-Black.ttf);
}

@font-face {
  font-family: "Poppins-Bold";
  color: $primaryDarkColor !important;
  font-weight: 700;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Poppins-ExtraBold";
  color: $primaryDarkColor !important;
  font-weight: 800;
  src: url(assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  color: $primaryDarkColor !important;
  font-weight: 500;
  src: url(assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Poppins-Regular";
  color: $primaryDarkColor !important;
  font-weight: 400;
  src: url(assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins-SemiBold";
  color: white !important;
  font-weight: 600;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "Poppins-ExtraLight";
  color: $primaryDarkColor !important;
  font-weight: 200;
  src: url(assets/fonts/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: "Poppins-Light";
  color: $primaryDarkColor !important;
  font-weight: 300;
  src: url(assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "Poppins-Thin";
  color: $primaryDarkColor !important;
  font-weight: 100;
  src: url(assets/fonts/Poppins-Thin.ttf);
}


body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
}

#root{
  z-index: 2147483647000;
}

iframe{
  z-index: -1 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  box-sizing: border-box;
}

.head1 {
  font-size: 32px;
  font-weight: 900;
}

.head2 {
  font-size: 24px;
  font-weight: 800;
}

.head3 {
  font-size: 19px;
  font-weight: 700;
}

.head4 {
  font-size: 16px;
  font-weight: 600;
}

.head5 {
  font-size: 14px;
  font-weight: 500;
}

.head6 {
  font-size: 12px;
  font-weight: 400
}

.p1 {
  font-size: 32px;
}

.p2 {
  font-size: 24px;
}

.p3 {
  font-size: 19px;
}

.p4 {
  font-size: 16px
}

.p5 {
  font-size: 14px;
}

.p6 {
  font-size: 12px;
}

.customModal {
  border-radius: 30px;
  padding: 0px;
  position: absolute;
  right: 300px;
  width: 450px;
  height: 550px;
  bottom: 120px;
}
::-webkit-scrollbar {
  display: none;
}
.setting_modal{
  border-radius: 30px;
  padding: 0px;
  width: 450px;
}

.selectDogModal{
  border-radius:20px;
  height: 40vh;
  width: 40vh;
}
.react-responsive-modal-container{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}