@import "../../index.scss";

.main_hoc_container {
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  font-family: Poppins-Regular;
  .main_hoc_wrapper {
    // width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // overflow-x: hidden;
    .main_hoc_sidebar_container {
      width: 20%;
      height: 100%;
      .main_hoc_sidebar_wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .main_hoc_sidebar_body {
          .main_hoc_sidebar_logo_content {
            width: 100%;
            padding: 2.5em 0 2.5em 0;
            display: flex;
            justify-content: center;
            .hoc_logo {
              // width: 200px;
              // height: 70px;
            }
          }
          .main_hoc_sidebar_tab_container {
            background-color: $buttonTextColor;
            border-radius: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .main_hoc_sidebar_tab_content {
              width: 80%;
              .tab_content {
                margin: 1.5em 0;
                display: flex;
                align-items: center;
                padding: 1em 0.5em;
                // height: 56px;
                border-radius: 20px;
                cursor: pointer;
                text-decoration: none;
                .tab_icon {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 20%;
                  // padding-left: 1.5em;
                }
                .tab_title {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  color: $primaryDarkText;
                  width: 80%;
                }
              }
              .tab_content:hover {
                // background-color: $secondaryLightColor;
              }
              .active {
                background-color: $primaryDarkColor;
                .tab_title {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  font-weight: 600;
                  color: $buttonTextColor;
                }
              }
            }
          }
        }
        .logout_container {
          padding: 2.5em 0 4em 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .logout_wrapper {
            width: 80%;
            padding: 1em 0.5em;
            border: 1px solid $otpOutLine;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 400;
            color: $primaryDarkText;
            cursor: pointer;
          }
        }
      }
    }
    .main_hoc_header_container {
      width: 15%;
      height: 100%;
      .main_hoc_header_wrapper {
        padding: 2.5em 0 2.5em 0;
        display: flex;
        // width: 100%;
        // height: 100%;
        justify-content: flex-start;
        align-items: center;

        .hoc_profile_container {
          margin-left: 10px;
          width: 60px;
          height: 60px;
        }
      }
    }

    .main_hoc_body {
      width: 60%;
      height: 100%;
      position: relative;
      // background-color: blue;
    }
  }
}
