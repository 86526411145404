@import '../../index.scss';
.plancard_container {
  width: 320px;

  .plancard_wrapper {
    border: 2px solid #c4cacc;
    border-radius: 19px;
    padding: 20px;
    // margin: 10px;
    width: 100%;
    .plancard_header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .plancard_header_wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 80%;
      }
      .plancard_header_text {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Poppins-Medium';
      }
      .plancard_plan_status {
        background-color: #edf6f0;
        padding: 5px 14px;
        color: #47a16a;
        border-radius: 32px;
        font-size: 10px;
        font-family: 'Poppins-Regular';
      }
    }
    .plancard_amount_container {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: flex-end;
      .plancard_amount {
        font-family: 'Poppins-SemiBold';
        font-size: 24px;
      }
      .plancard_duration {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        padding-bottom: 6px;
        text-transform: capitalize;
      }
    }
    .plancard_footer {
      .plancard_footer_text {
        font-size: 14px;
        font-family: 'Poppins-Regular';
      }
      .plancard_footer_button {
        width: 150px;
        .cancel_button {
          margin: 0px !important;
          height: 40px;
          border-radius: 8px !important;
          margin-top: 20px !important;
          font-size: 12px;
          font-family: 'Poppins-Regular';
          background-color: transparent;
          color: $primaryDarkColor;
          outline: 1px solid $primaryDarkColor;
        }
        .active_button {
          margin: 0px !important;
          height: 40px;
          border-radius: 8px !important;
          margin-top: 20px !important;
          font-size: 12px;
          font-family: 'Poppins-Regular';

        }
      }
    }
  }
  .plancard_header_checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
