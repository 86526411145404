@import '../../../index.scss';

.button_component {
  width: 100%;
  height: 55px;

  .activity_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 300px;
    background-color: $primaryDarkColor;
    .spinner_img {
      width: 25px;
      height: 25px;
    }
  }

  .button {
    width: 100%;
    height: 100%;
    background-color: $primaryDarkColor;
    color: $buttonTextColor;
    outline: none;
    border-radius: 300px;
    border: none;
    cursor: pointer;
  }
  .button_component_disabled {
    width: 100%;
    height: 100%;
    background-color: $primaryLightText;
    color: $buttonTextColor;
    outline: none;
    border-radius: 300px;
    border: none;
    cursor: pointer;
    // opacity: 0.3;
  }
}
